import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, addDoc, onSnapshot, serverTimestamp, query, orderBy, doc, getDoc } from 'firebase/firestore';
import { Box, TextField, Button, Container, Typography, Paper, Alert, Checkbox, FormControlLabel, Snackbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ChatIcon from '@mui/icons-material/Chat';
import WarningAmberIcon from '@mui/icons-material/WarningAmber'; // Importamos el ícono de aviso
import { motion } from 'framer-motion';
import html2canvas from 'html2canvas';

function Predictions() {
  const [predictions, setPredictions] = useState([]);
  const [predictionNumbers, setPredictionNumbers] = useState('');
  const [hasSubmittedPrediction, setHasSubmittedPrediction] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Estado para el Snackbar
  const [selectedOptions, setSelectedOptions] = useState({
    quiniela: false,
    pale: false,
    tripleta: false,
  });
  const username = localStorage.getItem('username');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        if (username) {
          const userDocRef = doc(db, 'users', username);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            setUserRole(userDoc.data().role || 'normal');
          } else {
            setErrorMessage('Error: Usuario no encontrado.');
          }
        } else {
          setErrorMessage('Error: Usuario no autenticado.');
        }
      } catch (error) {
        setErrorMessage('Error al obtener el rol del usuario.');
        console.error('Error fetching user role:', error);
      }
    };
    fetchUserRole();
  }, [username]);

  useEffect(() => {
    try {
      const unsubscribePredictions = onSnapshot(
        query(collection(db, 'predictions'), orderBy('timestamp', 'desc')),
        (snapshot) => {
          const loadedPredictions = snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }));
          setPredictions(loadedPredictions);
        },
        (error) => {
          setErrorMessage('Error al cargar predicciones.');
          console.error('Error fetching predictions:', error);
        }
      );
  
      return () => unsubscribePredictions();
    } catch (error) {
      setErrorMessage('Error al inicializar las predicciones.');
      console.error('Error setting up predictions listener:', error);
    }
  }, []);

  const handlePredictionInput = (e) => {
    let value = e.target.value.replace(/[^0-9]/g, ''); 
    if (value.length > 10) value = value.slice(0, 10); 

    const formattedValue = value.match(/.{1,2}/g)?.join('-') || '';
    setPredictionNumbers(formattedValue);
  };

  const handleOptionChange = (event) => {
    setSelectedOptions({
      ...selectedOptions,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSubmitPrediction = async () => {
    if (!predictionNumbers.trim()) return;

    const numbersArray = predictionNumbers.split('-').map(num => num.trim());

    if (numbersArray.length !== 5 || numbersArray.some(num => isNaN(num) || num < 0 || num > 99)) {
      alert('Por favor, ingrese 5 números entre 00 y 99.');
      return;
    }

    try {
      await addDoc(collection(db, 'predictions'), {
        username,
        numbers: numbersArray.join('-'),
        options: selectedOptions,
        timestamp: serverTimestamp(),
      });
      setPredictionNumbers('');
      setSelectedOptions({ quiniela: false, pale: false, tripleta: false });
      setHasSubmittedPrediction(true);
    } catch (error) {
      setErrorMessage('Error al guardar la predicción.');
      console.error('Error saving prediction:', error);
    }
  };

  // Función para mostrar mensaje informativo al hacer clic en el botón "VIP"
  const handleVIPClick = () => {
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Función para generar la imagen, ajustar tamaño dinámicamente y agregar marca de agua
  const handleDownloadImage = (commentId) => {
    const element = document.getElementById(`comment-${commentId}`);
    const button = element.querySelector('.download-btn');

    // Ocultar el botón de descarga temporalmente
    button.style.display = 'none';

    // Generar la imagen con html2canvas
    html2canvas(element).then((canvas) => {
      const ctx = canvas.getContext('2d');

      // Agregar marca de agua más visible en la parte inferior derecha
      ctx.font = 'bold 24px Arial';
      ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
      ctx.textAlign = 'right';
      ctx.fillText('Chatsrd.com', canvas.width - 20, canvas.height - 20);

      // Crear el enlace para descargar la imagen
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = `prediccion-${commentId}.png`;
      link.click();

      // Mostrar el botón de nuevo después de la captura
      button.style.display = 'inline-block';
    });
  };

  // Función para renderizar los números en círculos estilo "bolos de lotería"
  const renderNumbersInCircles = (numbersString) => {
    const numbersArray = numbersString.split('-');
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px', mt: 1 }}>
        {numbersArray.map((num, index) => (
          <Box 
            key={index} 
            sx={{
              width: '50px',
              height: '50px',
              borderRadius: '50%',
              backgroundColor: '#ff6f00',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontWeight: 'bold',
              fontSize: '18px',
              color: '#fff',
            }}
          >
            {num}
          </Box>
        ))}
      </Box>
    );
  };

  const renderSelectedOptions = (options) => {
    if (!options) return ''; // Si options es undefined, retornamos una cadena vacía

    const selected = [];
    if (options.quiniela) selected.push('Quiniela');
    if (options.pale) selected.push('Pale');
    if (options.tripleta) selected.push('Tripleta');
    return selected.join(', ');
  };

  if (userRole === 'normal') {
    return (
      <Container maxWidth="sm">
        <Box 
          sx={{ 
            mt: 8, 
            textAlign: 'center', 
            padding: '20px', 
            borderRadius: '12px', 
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', 
            backgroundColor: '#fff' 
          }}
        >
          <Typography variant="h4" color="error" gutterBottom>
            Acceso Denegado
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Solo los usuarios <strong>Oro</strong> y <strong>Platino</strong> pueden acceder a las predicciones.
          </Typography>

          <Button
            variant="contained"
            fullWidth
            color="primary"
            startIcon={<ChatIcon />}
            onClick={() => navigate('/chat')}
            sx={{ 
              mb: 2, 
              padding: '12px 0', 
              fontWeight: 'bold',
              fontSize: '16px',
              borderRadius: '8px',
              backgroundColor: '#1976d2',
              '&:hover': {
                backgroundColor: '#115293',
              }
            }}
          >
            Volver al Chat
          </Button>
          

          <Button
            variant="outlined"
            fullWidth
            startIcon={<WhatsAppIcon />}
            onClick={() => window.open('https://wa.me/18298415339', '_blank')}
            sx={{ 
              padding: '12px 0', 
              fontWeight: 'bold', 
              fontSize: '16px',
              borderRadius: '8px',
              borderColor: '#25D366',
              color: '#25D366',
              '&:hover': {
                borderColor: '#1ebe57',
                color: '#1ebe57',
              }
            }}
          >
            Contactar por WhatsApp
          </Button>
        </Box>
      </Container>
    );
  }

  if (errorMessage) {
    return (
      <Container maxWidth="sm">
        <Box sx={{ mt: 4 }}>
          <Alert severity="error">{errorMessage}</Alert>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Box 
        sx={{ 
          mt: 4, 
          padding: '20px', 
          backgroundColor: '#f0f4f8', 
          borderRadius: '10px', 
          boxShadow: '0 0 10px rgba(0,0,0,0.1)',
          textAlign: 'center',
        }}
      >
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', color: '#1976d2' }}>
            Publicar Predicciones
          </Typography>
          <TextField
            label="Ingrese 5 números recomendados"
            variant="outlined"
            fullWidth
            value={predictionNumbers}
            onChange={handlePredictionInput}
            disabled={hasSubmittedPrediction}
            sx={{ marginBottom: 2, backgroundColor: '#fff' }}
          />
          <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center', gap: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedOptions.quiniela}
                  onChange={handleOptionChange}
                  name="quiniela"
                  disabled={hasSubmittedPrediction}
                  sx={{
                    color: '#ff6f00',
                    '&.Mui-checked': {
                      color: '#ff6f00',
                    },
                  }}
                />
              }
              label="Quiniela"
              sx={{ color: '#ff6f00', fontWeight: 'bold' }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedOptions.pale}
                  onChange={handleOptionChange}
                  name="pale"
                  disabled={hasSubmittedPrediction}
                  sx={{
                    color: '#1976d2',
                    '&.Mui-checked': {
                      color: '#1976d2',
                    },
                  }}
                />
              }
              label="Pale"
              sx={{ color: '#1976d2', fontWeight: 'bold' }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedOptions.tripleta}
                  onChange={handleOptionChange}
                  name="tripleta"
                  disabled={hasSubmittedPrediction}
                  sx={{
                    color: '#388e3c',
                    '&.Mui-checked': {
                      color: '#388e3c',
                    },
                  }}
                />
              }
              label="Tripleta"
              sx={{ color: '#388e3c', fontWeight: 'bold' }}
            />
          </Box>


          
          <Button
            variant="contained"
            fullWidth
            color="primary"
            onClick={handleSubmitPrediction}
            disabled={hasSubmittedPrediction}
            sx={{ padding: '10px', fontWeight: 'bold' }}
          >
            Guardar Predicción
          </Button>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom sx={{ textAlign: 'center', color: '#333' }}>
              Predicciones Publicadas
            </Typography>
            {predictions.length === 0 ? (
              <Typography>No hay predicciones publicadas.</Typography>
            ) : (
              predictions.map(({ id, data }) => (
                <motion.div
                  key={id}
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.4 }}
                  id={`comment-${id}`} // ID único para cada comentario
                  sx={{
                    backgroundColor: '#ffffff',
                    borderRadius: '12px',
                    padding: '20px',
                    boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                  }}
                >
                  <Paper sx={{ p: 2, mb: 2, backgroundColor: '#e3f2fd', borderRadius: '12px' }}>
                    <Typography 
                      variant="subtitle1" 
                      sx={{ fontWeight: 'bold', color: '#1a237e', fontSize: '18px', textTransform: 'uppercase' }}  // Nombres en mayúscula
                    >
                      {data.username}
                    </Typography>
                    <Typography variant="body2" sx={{ fontStyle: 'italic', color: '#616161' }}>
                      {data.timestamp ? new Date(data.timestamp.seconds * 1000).toLocaleString() : 'Fecha no disponible'}
                    </Typography>

                    {/* Renderizamos los números dentro de círculos */}
                    {renderNumbersInCircles(data.numbers)}

                    <Typography sx={{ mt: 1, color: '#000' }}>
                      Selecciones: {renderSelectedOptions(data.options)}
                    </Typography>

                    <Button
                      className="download-btn"  // Agregamos clase para ocultar en canvas
                      variant="contained"
                      color="secondary"
                      onClick={() => handleDownloadImage(id)}  // Evento de click para generar imagen del comentario específico
                      sx={{
                        backgroundColor: '#1976d2',
                        color: '#fff',
                        fontSize: '12px', // Botón más pequeño
                        mt: 2,
                        padding: '5px 10px', // Tamaño reducido del botón
                        fontWeight: 'bold',
                        '&:hover': {
                          backgroundColor: '#115293',
                        },
                        borderRadius: '6px'
                      }}
                    >
                      Descargar
                    </Button>

                    {/* Botón VIP */}
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleVIPClick}  // Cambiamos para mostrar Snackbar en lugar de alert
                      sx={{
                        backgroundColor: '#FFD700', // Color dorado para VIP
                        color: '#333',
                        fontSize: '12px',
                        mt: 2,
                        padding: '5px 10px',
                        fontWeight: 'bold',
                        borderRadius: '6px',
                        '&:hover': {
                          backgroundColor: '#FFC107',
                        }
                      }}
                    >
                      VIP
                    </Button>
                  </Paper>
                </motion.div>
              ))
            )}
          </Box>
        </motion.div>

        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate('/chat')}
            sx={{
              backgroundColor: '#4caf50',
              color: '#fff',
              padding: '10px 20px',
              fontSize: '16px',
              fontWeight: 'bold',
              '&:hover': {
                backgroundColor: '#388e3c',
              },
              borderRadius: '8px'
            }}
          >
            Volver al Chat
          </Button>
        </Box>
      </Box>

      {/* Snackbar para mensaje informativo */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000} // Duración en ms
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="warning"
          sx={{ fontSize: '18px', display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
          icon={<WarningAmberIcon fontSize="large" />} // Ícono de aviso grande
        >
          Usted no tiene monedas para entrar a esta sesión.
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default Predictions;
