import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { Box, TextField, Button, Typography, Container, FormControlLabel, Checkbox, IconButton, Alert } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [rememberMe, setRememberMe] = useState(true);
  const [error, setError] = useState('');
  const [maintenanceMessage, setMaintenanceMessage] = useState(false);
  const [forgotMode, setForgotMode] = useState(false);
  const [recoveredCredentials, setRecoveredCredentials] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const savedUsername = localStorage.getItem('rememberedUsername');
    const savedPassword = localStorage.getItem('rememberedPassword');

    if (savedUsername && savedPassword) {
      setUsername(savedUsername);
      setPassword(savedPassword);
      setRememberMe(true);
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setMaintenanceMessage(false); // Reiniciar el mensaje de mantenimiento
    setError(''); // Reiniciar cualquier mensaje de error previo

    try {
      const normalizedUsername = username.trim().toLowerCase();
      const userDocRef = doc(db, 'users', normalizedUsername);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        setError('El usuario no existe');
        return;
      }

      const userData = userDoc.data();

      if (userData.isBlocked) {
        setError('Su cuenta ha sido bloqueada. No puede acceder al chat.');
        return;
      }

      if (userData.password !== password) {
        setError('Contraseña incorrecta');
        return;
      }

      // Confirmar rol después de verificar credenciales
      if (userData.role === 'normal') {
        setMaintenanceMessage(true); // Mostrar mensaje de mantenimiento solo a usuarios "normal"
      } else {
        // Guardar credenciales si está marcada la opción "Recordar"
        if (rememberMe) {
          localStorage.setItem('rememberedUsername', normalizedUsername);
          localStorage.setItem('rememberedPassword', password);
        } else {
          localStorage.removeItem('rememberedUsername');
          localStorage.removeItem('rememberedPassword');
        }
        // Continuar al chat si el rol es diferente a "normal"
        localStorage.setItem('username', normalizedUsername);
        navigate('/chat');
      }
    } catch (error) {
      console.error('Error logging in:', error);
      setError('Error al iniciar sesión. Inténtalo de nuevo.');
    }
  };

  const handleWhatsAppClick = () => {
    const whatsappUrl = "https://wa.me/18298415339?text=Hola,%20necesito%20ayuda%20con%20mi%20inicio%20de%20sesión.";
    window.open(whatsappUrl, '_blank');
  };

  const handleForgotPassword = async () => {
    try {
      if (!phoneNumber) {
        setError('Por favor ingrese un número de teléfono válido');
        return;
      }

      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('phone', '==', phoneNumber));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setError('No se encontró ningún usuario con ese número de teléfono');
        return;
      }

      const userData = querySnapshot.docs[0].data();
      setRecoveredCredentials({ username: userData.username, password: userData.password });
      setError('');
    } catch (error) {
      console.error('Error al recuperar credenciales:', error);
      setError('Error al recuperar credenciales. Verifique el número e inténtelo nuevamente.');
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          {forgotMode ? 'Recuperar Credenciales' : 'Acceso'}
        </Typography>
        
        {/* Muestra el mensaje de error o de mantenimiento */}
        {error && <Typography color="error">{error}</Typography>}
        {maintenanceMessage ? (
          <Alert severity="warning" sx={{ mt: 2 }}>
            Este chat está en mantenimiento para usuarios con rol "normal". Contacta a soporte para obtener más información.
          </Alert>
        ) : (
          !forgotMode ? (
            <Box component="form" onSubmit={handleLogin} sx={{ mt: 3 }}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Nombre de usuario"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="password"
                label="La Clave es de 4 números"
                name="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                inputProps={{ maxLength: 4 }}
              />
              <FormControlLabel
                control={<Checkbox checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} color="primary" />}
                label="Recordar mis datos"
              />
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                Acceso
              </Button>
              <Button variant="text" onClick={() => setForgotMode(true)}>
                ¿Olvidó su contraseña o usuario?
              </Button>
            </Box>
          ) : (
            <Box sx={{ mt: 3 }}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="phoneNumber"
                label="Número de teléfono"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleForgotPassword}>
                Recuperar Credenciales
              </Button>
              <Button variant="text" onClick={() => setForgotMode(false)}>
                Volver al inicio de sesión
              </Button>

              {recoveredCredentials && (
                <Box sx={{ mt: 3 }}>
                  <Typography variant="h6">Tus credenciales son:</Typography>
                  <Typography>Usuario: {recoveredCredentials.username}</Typography>
                  <Typography>Contraseña: {recoveredCredentials.password}</Typography>
                </Box>
              )}
            </Box>
          )
        )}

        <IconButton color="primary" sx={{ mt: 2 }} onClick={handleWhatsAppClick}>
          <WhatsAppIcon sx={{ fontSize: 40, color: 'green' }} />
        </IconButton>
        <Typography variant="body2" sx={{ mt: 1 }}>
          Contáctanos por WhatsApp
        </Typography>
      </Box>
    </Container>
  );
}

export default Login;
